<template>
  <v-main class="full-height-80">
    <v-card v-if="loading" class="mt-4">
      <v-skeleton-loader type="card-avatar, article, list-item-three-line, actions"/>
    </v-card>
    <v-row no-gutters v-else>
      <v-col cols="12" xs="12" sm="12" md="7" :class="!['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'full-height-' : ''">
        <div >
          <section>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card-title class="primary--text">
                  <b>{{$t('pageBio')}}</b>
                  <v-spacer/>
                  <v-badge overlap right bordered color="blue" :value="!screenData.auth.user.config" content="!">
                    <v-btn color="primary" @click="dialogConfig = true" v-bind="attrs" v-on="on" outlined class="rounded-lg text-none">
                      <v-icon>mdi-cog</v-icon>
                      <label>{{ $t('config') }}</label>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-badge>
                </v-card-title>
                <!-- <v-card-subtitle>{{$t('createBio')}}</v-card-subtitle> -->
              </v-col>
              <v-col cols="12">
                <v-card class="card-corner-radius mt-0" elevation="0">
                  <v-card-title v-if="loading">
                    <v-skeleton-loader type="chip"/>
                  </v-card-title>
                  <v-card-text v-else-if="bio.id">
                    <v-row no-gutters>
                      <v-col cols="12" sm="8">
                        <v-chip color="primary" class="primary--text pa-2" outlined>
                          <input :id="`link-${bio.onlineAddress.id}`"  :value="`${host}/${bio.onlineAddress.code}`" class="primary--text" readonly  style="width: 200px;border: 0px;focu"/>
                          <v-icon color="primary" class="ml-1" @click="copyLink(bio.onlineAddress)">
                            mdi-content-copy
                          </v-icon>
                          <v-slide-x-transition>
                            <v-icon color="green" class="ml-1" v-if="bio.onlineAddress.copy">
                              mdi-check-circle
                            </v-icon>
                          </v-slide-x-transition>
                        </v-chip>
                      </v-col>
                      <v-spacer/>
                      <v-col cols="12" sm="4">
                        <v-card-actions class="pa-0">
                          <v-spacer/>
                          <v-chip color="transparent">
                            <ShareOptions :onlineAddress="bio.onlineAddress"/>
                            <span class="ml-2">{{ $t('share') }}</span>
                          </v-chip>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </section>
          <section>
            <v-form v-model="form">
              <v-card class="card-corner-radius mt-4" elevation="0">
                <v-card-text>
                  <v-card class="card-corner-radius">
                    <v-img :src="bio.cover ? bio.cover.src : null" height="250" class="grey" aspect-ratio position="top">
                      <v-app-bar flat color="rgba(0, 0, 0, 0)">
                        <v-spacer/>
                        <v-btn color="primary" rounded class="pl-5">
                          <v-file-input
                            @change="handleCover"
                            dark
                            hide-input
                            hide-details
                            hide-actions
                            prepend-icon="mdi-image"
                            icon
                            accept="image/png, image/jpeg, image/bmp"
                          />
                        </v-btn>
                        <v-btn color="red" rounded class="ml-2" @click="bio.cover ? bio.cover.src = null : bio.cover = null" :disabled="!bio.cover || !bio.cover.src">
                          <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                      </v-app-bar>
                      <v-card-actions class="white--text mt-8">
                        <v-spacer/>
                        <v-btn color="primary" rounded dark class="mr-2 pl-5">
                          <v-file-input
                            class="mt-0 pt-0 mr-0 pr-0"
                            @change="handlePicture"
                            hide-input
                            prepend-icon="mdi-camera"
                            hide-details
                            hide-actions
                            hide-controls
                            accept="image/png, image/jpeg, image/bmp"
                          />
                        </v-btn>
                        <v-card class="rounded-card">
                          <v-card-text class="pt-1 pl-1 pr-1 pb-1">
                            <v-avatar size="100" >
                              <v-img :src="bio.picture.src" v-if="bio.picture && bio.picture.src"/>
                              <v-icon v-else>mdi-camera</v-icon>
                            </v-avatar>
                          </v-card-text>
                        </v-card>
                        <v-btn color="red" rounded class="ml-2" @click="bio.picture ? bio.picture.src = null : bio.picture = null" :disabled="!bio.picture || !bio.picture.src">
                          <v-icon color="white">mdi-delete</v-icon>
                        </v-btn>
                        <v-spacer/>
                      </v-card-actions>
                    </v-img>
  
                  </v-card>
                </v-card-text>
                <v-card-text>
                  <v-divider/>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" sm="6">
                      <v-text-field
                        v-model="bio.onlineAddress.code"
                        outlined
                        placeholder="Usuário"
                        dense
                        rounded
                        maxlength="15"
                        required
                        counter
                        @keyup="bio.onlineAddress.code ? verifyUserName() : null"
                        :rules="[
                          v => !!v || $t('required')
                        ]">
                        <template v-slot:append>
                          <v-slide-x-transition hide-on-leave leave-absolute>
                            <v-progress-circular indeterminate size="25" width="1" color="primary" v-if="loadingVerifyUserName"/>
                            <v-icon v-else-if="canUseUserName" color="green">mdi-check</v-icon>
                            <v-icon v-else-if="!canUseUserName" color="red">mdi-cancel</v-icon>
                          </v-slide-x-transition>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="bio.name"
                        outlined
                        rounded
                        dense
                        label="Name"
                        maxlength="20"
                        required
                        counter
                        :rules="[
                          v => !!v || $t('required')
                        ]"/>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="bio.description"
                        outlined
                        dense
                        rounded
                        flat
                        no-resize
                        :label="$t('description')"
                        maxlength="999"
                        :rules="[
                          v => !!v || $t('required')
                        ]"/>
                        counter/>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text>
                  <v-divider/>
                </v-card-text>
                <v-card-title>
                  {{ $t('links') }}
                </v-card-title>
                <v-card-text v-for="(biographyHasOnlineAddress, index) in bio.biographyHasOnlineAddresses" :key="index">
                  <v-slide-x-transition>
                    <v-card class="card-corner-radius card-link" v-if="!biographyHasOnlineAddress.deleted" elevation="0" :color="biographyHasOnlineAddress.onlineAddress.error ? 'warning' : '#D2DBEB'">
                      <v-card class="card-corner-radius" elevation="0">
                        <v-card-title>
                          <b>{{index + 1}}º {{$t('link')}}</b>
                          <v-spacer/>
                          <v-menu offset-y centered v-if="biographyHasOnlineAddress.onlineAddress.error" >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="warning"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                icon
                                class="mt-2"
                              >
                                <v-icon>mdi-alert-circle</v-icon>
                              </v-btn>
                            </template>
                            <v-card color="warning" style="padding: 1px;" max-width="500">
                              <v-card elevation="0">
                                <v-card-title>
                                  <b>{{ $t('linkErrorTitle') }}</b>
                                </v-card-title>
                                <v-card-subtitle>
                                  {{`${$t('thisLinkError')} ${new Date(biographyHasOnlineAddress.onlineAddress.lastCheck.replace('[UTC]' , ''))}`}}.
                                </v-card-subtitle>
                                <v-card-subtitle>{{ $t('possibleCauses') }}</v-card-subtitle>
                                <v-card-text>
                                  <p v-for="item in $t('linkErrorCauses')" :key="item">
                                    - {{ item }}
                                  </p>
                                </v-card-text>
                              </v-card>
                            </v-card>
                          </v-menu>
                          <v-btn icon color="red" class="mt-2" @click="biographyHasOnlineAddress.deleted = true, biographyHasOnlineAddress.onlineAddress.deleted = true">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-subtitle>
                          <v-switch :label="$t('onlyIcon')" v-model="biographyHasOnlineAddress.onlyIcon" inset />
                        </v-card-subtitle>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" xs="12" sm="12">
                              <v-card v-if="isWhatsapp(biographyHasOnlineAddress.onlineAddress)" dark color="green darken-4" class="mt-2">
                                <v-card-actions>
                                  {{getNumber(biographyHasOnlineAddress.onlineAddress)}}
                                  <v-spacer/>
                                  <v-icon>
                                    mdi-whatsapp
                                  </v-icon>
                                </v-card-actions>
                              </v-card>
                              <v-text-field
                                v-else
                                :disabled="biographyHasOnlineAddress.onlineAddress.id && !screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]"
                                v-model="biographyHasOnlineAddress.onlineAddress.url"
                                outlined
                                rounded
                                dense
                                flat
                                :rules="[rules.url]"
                                prepend-inner-icon="mdi-link"
                                hide-details
                                placeholder="https://"
                                label="Link"
                                class="lowercase"/>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" v-if="!biographyHasOnlineAddress.onlyIcon">
                              <v-text-field
                                v-model="biographyHasOnlineAddress.onlineAddress.tags"
                                outlined
                                hide-details
                                dense
                                rounded
                                :label="$t('description')"
                                :rules="[
                                  v => !!v || $t('required')
                                ]"/>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-card>
                    <v-card v-else class="card-corner-radius"  elevation="0" color="grey lighten-2">
                      <v-card class="card-corner-radius" elevation="0" color="grey lighten-2">
                        <v-card-title>
                          <b>{{index + 1}}º {{$t('link')}}</b>
                        </v-card-title>
                        <v-card-subtitle>
                          {{ biographyHasOnlineAddress.onlineAddress.tags }} - {{ biographyHasOnlineAddress.onlineAddress.url }}
                        </v-card-subtitle>
                        <v-card-actions>
                          <v-spacer/>
                          <i>{{ $t('excluded') }}</i>
                        </v-card-actions>
                      </v-card>
                    </v-card>
                  </v-slide-x-transition>
                </v-card-text>
                <v-card-actions>
                  <v-chip color="primary" outlined @click="bio.biographyHasOnlineAddresses.push(getNewLink())">
                    {{$t('newLink')}}
                  </v-chip>
                  <v-spacer/>
                  <v-chip @click="whatsappDialog = true" color="transparent">
                    <v-icon color="green">mdi-whatsapp</v-icon>
                    <div class="green--text ml-2">{{$t('newLinkWhatsapp')}}</div>
                  </v-chip>
                </v-card-actions>
                <v-card-text>
                  <v-divider/>
                </v-card-text>
                <v-card-text v-if="!screenData.auth.user.userHasSubscriptionPlans.filter(v => v.subscriptionPlan.name.includes('URL_SHORTNER')).filter(v => ['URL_SHORTNER_BASIC', 'URL_SHORTNER_PRO', 'URL_SHORTNER_BUSINESS'].includes(v.subscriptionPlan.name))[0]">
                  <v-alert dense small color="#78FFFF">
                    <v-card-actions style="color: #0C9B93;">
                      <v-icon color="#0C9B93" class="mr-2">mdi-information-slab-circle-outline</v-icon>
                      {{$t('deleteAddressInfoPlan')}}
                    </v-card-actions>
                    <v-card-actions>
                      <v-spacer/>
                      <IconInfoSubscriblePlan :screenData="screenData" :planName="$t('plan_BASIC')" :planData="planData"/>
                    </v-card-actions>
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-tooltip right v-if="false">
                    <template v-slot:activator="{ on, attrs }">
                      <a :href="`${protocol}//${host}/${bio.onlineAddress.code}`" target="_blank" class="link" v-bind="attrs" v-on="on">
                        <v-btn text v-if="bio.id" color="blue" :disabled="loadingSave">
                          <span v-if="!['xs'].includes($vuetify.breakpoint.name)">{{$t(`viewUrl`).toUpperCase()}}</span>
                          <v-icon small>mdi-navigation-variant</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    <span>{{$t(`viewUrl`).toUpperCase()}}</span>
                  </v-tooltip>
                  <v-spacer/>
                  <v-tooltip right v-if="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="green" text @click="$router.push(`/painel/links/shortened/?onlineAddressId=${bio.onlineAddress.id}&shortenedRecent=${bio.onlineAddress.id}&typeIn=BIO`)" :disabled="loadingSave || !bio.id">
                        <span v-if="!['xs'].includes($vuetify.breakpoint.name)">{{$t('followMetrics')}}</span>
                        <v-icon small>mdi-chart-arc</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('followMetrics')}}</span>
                  </v-tooltip>
                  <v-btn rounded color="primary" :loading="loadingSave" @click="save" :disabled="!form || valid || loadingVerifyUserName || !canUseUserName" class="pl-5 pr-5" :block="['xs'].includes($vuetify.breakpoint.name)">
                    Salvar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </section>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="5" :class="!['xs', 'sm'].includes($vuetify.breakpoint.name) ? 'full-height' : ''">
        <v-card class="center card-corner-radius mt-4" elevation="0">
          <v-card-title>
            <v-spacer/>
              <v-chip color="transparent" text @click="$router.push(`/painel/links/shortened/?onlineAddressId=${bio.onlineAddress.id}&shortenedRecent=${bio.onlineAddress.id}&typeIn=BIO`)" :disabled="loadingSave || !bio.id">
                <v-icon small color="blue">mdi-chart-bar</v-icon>
                <span class="ml-2 blue--text">{{$t('followMetrics')}}</span>
              </v-chip>
            <v-spacer/>
          </v-card-title>
          <v-card-text>
            <v-img src="/mockup-iphone-02.svg" class="mobile-preview">
              <div class="mobile-preview-container">
                <BiographySmartphoneView :bio="bio" :onlySmartphone="false"/>
              </div>
            </v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <a :href="`${protocol}//${host}/${bio.onlineAddress.code}?countClick=false`" target="_blank" class="link" v-bind="attrs" v-on="on">
              <v-chip text v-if="bio.id" color="transparent" :disabled="loadingSave">
                <v-icon small color="blue">mdi-navigation-variant</v-icon>
                <span class="ml-1 blue--text">{{$t(`viewUrl`)}}</span>
              </v-chip>
            </a>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-col>  
      <v-dialog dark v-model="whatsappDialog" width="400">
        <v-card color="green darken-4">
          <v-card-actions>
            <span>
              {{$t('generateWhatsAppLink')}}
            </span>
            <v-spacer/>
            <v-btn icon color="white" @click="whatsappDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-form v-model="whatsappForm">
            <v-card-title>
              <v-text-field
                v-model="whatsapp.number"
                outlined
                :label="$t('whatsAppNumber')"
                placeholder="5571999999999"
                prepend-inner-icon="mdi-cellphone"
                type="number"
                required
                :rules="[
                  v => !!v
                ]"
                />
              <v-textarea
                v-model="whatsapp.text"
                prepend-inner-icon="mdi-message"
                outlined
                :label="$t('whatsAppText')"
                maxlength="999"
                rows="3"
                no-resize
                counter/>
            </v-card-title>
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn text @click="generateWhatsappLink" :disabled="!whatsappForm">
                {{$t('save')}}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogQrcode" width="300">
        <QRCodeMigly v-if="bio && bio.onlineAddress &&  bio.onlineAddress.id" :value="`${host}/${bio.onlineAddress.code}`" :enableControlls="true"/>
      </v-dialog>
    </v-row>
    <v-bottom-sheet v-if="['xs' , 'sm'].includes($vuetify.breakpoint.name)" v-model="dialogConfig">
      <v-sheet class="overflow">
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-bottom-sheet>
    <v-navigation-drawer 
      v-else
      v-model="dialogConfig"
      fixed
      width="50%"
      height="100%"
      class="grey lighten-5"
      >
      <v-sheet>
        <v-card-actions>
          <v-btn icon color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer/>
          <b>{{ $t('config') }}</b>
          <v-spacer/>
          <v-btn icon color="#7E8EA6" @click="dialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-divider class="ma-4 mt-0"/>
        <Config :screenData="screenData"/>
      </v-sheet>
    </v-navigation-drawer>
    <v-overlay :value="dialogConfig"></v-overlay>
    <v-dialog v-model="planData.dialogPlansAndPrices" width="800">
      <v-card>
        <v-card-actions>
          <v-btn @click="planData.dialogPlansAndPrices = false" icon><v-icon color="primary">mdi-arrow-left</v-icon></v-btn>
          <v-spacer/>
        </v-card-actions>
        <v-card-text>
          <SubscriptionAndPlans :screenData="screenData"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import {apiV1Biographies} from "../../../service/resources/api-v1-biography"
import { apiV1OnlineAddresses } from "../../../service/resources/api-v1-online-addresses"
import BiographySmartphoneView from "../fragments/BiographySmartphoneView.vue"
import QRCodeMigly from "../fragments/QRCodeMigly.vue"
import ShareOptions from "../fragments/ShareOptions.vue"
import Config from "../fragments/Config.vue"
import SubscriptionAndPlans from '../fragments/SubscriptionAndPlans.vue';
import IconInfoSubscriblePlan from "../fragments/IconInfoSubscriblePlan.vue";
  export default {
    name: 'PainelBioPage',
    components: {
    SubscriptionAndPlans,
    BiographySmartphoneView,
    QRCodeMigly,
    ShareOptions,
    Config,
    IconInfoSubscriblePlan
},
    props: ['screenData'],
    data: () => ({
      dialogConfig: false,
      planData: {
        dialogPlansAndPrices: false
      },
      form: false,
      whatsappForm: false,
      dialogQrcode: false,
      host: null,
      protocol: null,
      whatsapp: {number: '', text: ''},
      whatsappDialog: false,
      bio: null,
      loadingSave: false,
      loading: true,
      rules: {url:false},
      loadingVerifyUserName: false,
      canUseUserName: true
    }),
    computed: {
      valid: function() {
        return !this.bio.name
          || !this.bio.onlineAddress.code
          || this.bio.biographyHasOnlineAddresses
          .filter(i => !i.deleted)
          .filter(i => (i.onlineAddress.onlyIcon && !i.onlineAddress.tags) || !i.onlineAddress.url).length != 0
          || this.bio.biographyHasOnlineAddresses
          .filter(i => !i.deleted)
          .filter(i => !i.id)
          .filter(i => !this.isUrl(i.onlineAddress.url)).length != 0
      }
    },
    mounted: function() {
      this.rules = {
        url: value => {
          return this.isUrl(value) || this.$t('invalidUrl')
        }
      }
      this.protocol = window.location.protocol
      this.host = window.location.host.replace('www.', '')
      this.loading = true
      apiV1Biographies.page({
        page: 0,
        size: 100,
        embedBiographyHasOnlineAddress: true,
        userAuth: true
      }).then(result => {
        if(result.data.content[0]) {
          this.bio = result.data.content[0]
        } else {
          this.bio = {
            host: null,
            picture: null,
            cover: null,
            name: '',
            biographyHasOnlineAddresses: [this.getNewLink()],
            onlineAddress: {code: ''}
          }
        }
        this.loading = false
      })
    },
    methods: {
      verifyUserName: function() {
        this.loadingVerifyUserName = true
        this.canUseUserName = false
        apiV1OnlineAddresses.get(this.bio.onlineAddress.code).then(response => {
          if(response.data) {
            this.canUseUserName = response.data.id == this.bio.onlineAddress.id
          } else {
            this.canUseUserName = true
          }
          this.loadingVerifyUserName = false
        }).catch(()=>{
          this.loadingVerifyUserName = false
        })
      },
      isWhatsapp: function(address) {
        return (address.url.includes('whatsapp') && address.url.includes('send')) || address.url.includes('wa.me')
      },
      getNumber: function(address) {
        return address.url
        .replace('https://api.whatsapp.com/send/?', '')
        .replace('phone=', 'N: ')
        .replace('&text=', ' M: ')
        .replace('&app_absent=0', '')
      },
      generateWhatsappLink: function(){
        let add = this.getNewLink()
        add.onlineAddress.tags = "Whatsapp"
        add.onlineAddress.url = `https://api.whatsapp.com/send/?phone=${this.whatsapp.number}&text=${this.whatsapp.text}`
        this.bio.biographyHasOnlineAddresses.push(add)
        this.whatsapp = {number: '', text: ''}
        this.whatsappDialog = false
      },
      isUrl: function(value) {
        const pattern = /[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)?/gi
        return pattern.test(value)
      },
      save: function() {
        this.loadingSave = true
        if(this.bio.cover && !this.bio.cover.src)
          this.bio.cover = null
        if(this.bio.picture && !this.bio.picture.src)
          this.bio.picture = null
        apiV1Biographies.post(this.bio).then(result => {
          this.bio = result.data
          this.loadingSave = false
          this.$commons.updateSnackbar({show: true, color: 'success', text: this.$t('saved')})
        }).catch(() => {
          this.loadingSave = false
        })
      },
      getNewLink: function() {
        return {
          deleted: false,
          biography: null,
          onlineAddress: {
            tags: '',
            url: '',
            active: true,
            type: 'BIO'
          }
        }
      },
      handleCover: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.bio.cover)
            self.bio.cover = {}
          self.bio.cover.src = reader.result
        }
      },
      handlePicture: function(event) {
        var reader = new FileReader()
        reader.readAsDataURL(event)
        let self = this
        reader.onload = function () {
          if(!self.bio.picture)
            self.bio.picture = {}
          self.bio.picture.src = reader.result
        }
      },
      copyLink: function (item) {
        let element = document.querySelector('#link-'+ item.id);
        element.setAttribute('type', 'text') 
        element.select()
        document.execCommand('copy');
        console.log(element)
        item.copy = true
        this.$forceUpdate()
        setTimeout(() => {
          item.copy = false
          this.$forceUpdate()
        }, 3000);
      },
    },
  }
</script>
<style>
.mobile-preview{
  margin: auto;
  /* top: 50%; */
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  width: 300px;
  height: 600px;
}
.center{
  margin: auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: fit-content;
}
.mobile-preview-container{
  height: 92%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x:hidden;
  margin-top: 36px !important;
  margin-left: 16.7px !important;
  margin-right: 17px !important;
  border-radius: 5px 5px 32px 33px;
}

.mobile-preview-container::-webkit-scrollbar {
  width: 0px;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.card-link{
  padding: 1px;
}

input:focus {
    outline: none; /* Remove a borda padrão do navegador */
    border: none;  /* Remove a borda definida */
}
</style>